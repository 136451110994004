import React, { useState, useEffect } from 'react';
import 'flowbite';
import { Link, useParams } from 'react-router-dom';
import * as GeneralService from '../../../services/general.service';
import parse from 'html-react-parser';
import { HomeBannerLoader, BlockLoader, HomeFeatureLoader, AboutSectionLoader, BlogSectionLoader } from 'components/interfaces/SkeletonLoader';
import Accordion from 'react-bootstrap/Accordion';

const bag1 = `${process.env.REACT_APP_BASE_URL}assets/images/home_box_bg1.jpg`;
const bag2 = `${process.env.REACT_APP_BASE_URL}assets/images/home_box_bg2.jpg`;
const bag3 = `${process.env.REACT_APP_BASE_URL}assets/images/home_btn_bg.jpg`;

// checked
export const Banner = () => {
    const [processing, setProcessing] = useState(false);
    const [banner, setBanner] = useState('');
    useEffect(() => {
        fetchBanner();
    }, []);

    const fetchBanner = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-banners/home-banner');
        if (res.response) {
            setProcessing(false);
            setBanner(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <HomeBannerLoader />
                    :
                    <>
                        {
                            banner !== '' && banner !== null
                                ?
                                <div className='float-left w-full lg:min-h-[450px] min-h-[400px] md:pt-0 pt-10 flex flex-wrap items-center banner_bg relative overflow-hidden'>
                                    <div className='xl:container container m-auto px-4 relative z-[1]'>
                                        <div className='flex md:flex-row flex-col items-center'>
                                            <div className='xl:w-8/12 md:w-6/12 w-full md:text-left text-center'>
                                                {parse(banner?.long_description ? banner?.long_description : '')}
                                                <Link to={`${process.env.REACT_APP_BASE_URL}contact-us`} className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out">Get started</Link>
                                            </div>
                                            <div className='xl:w-4/12 md:w-6/12 w-full'>
                                                <img className="lg:w-[565px] w-[465px] md:float-right m-auto md:absolute md:right-0 xl:bottom-[-135px] lg:bottom-[-115px] md:bottom-[-105px]" src={banner?.image_url} alt={banner?.title} />
                                            </div>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const WhatWeProvide = () => {
    const [processing, setProcessing] = useState(false);
    const [we_provide, setWeProvide] = useState('');
    useEffect(() => {
        fetchWhatWeProvide();
    }, []);

    const fetchWhatWeProvide = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms-section/what-we-provide');
        if (res.response) {
            setProcessing(false);
            setWeProvide(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <BlockLoader count={1} width={570} height={350} />
                    :
                    <>
                        {
                            we_provide !== '' && we_provide !== null
                                ?
                                <div className='bg-theme_gray float-left w-full py-14 overflow-hidden'>
                                    <div className='xl:container m-auto px-4'>
                                        <h2 className='text-theme_red md:text-[32px] text-[26px] font-Poppins_SemiBold pb-3'>What we provide?</h2>
                                        <div className='grid md:grid-cols-2 lg:gap-16 gap-10 pt-8'>
                                            <div className='relative rounded-2xl w-full bg-cover' style={{ backgroundImage: `url(${bag1})` }}>
                                                <div className='relative z-[1] float-left w-full lg:px-8 px-5 py-6'>
                                                    <div className='flex flex-wrap xl:flex-row gap-8 flex-col items-start justify-between pb-3'>
                                                        <img className="w-[205px] float-left" src={we_provide?.edukula_platform?.image} alt="" />
                                                        <h4 className='xl:w-6/12 w-full font-Poppins_SemiBold text-[22px] text-theme_black pb-3'>{we_provide?.edukula_platform?.title}</h4>
                                                    </div>
                                                    {parse(we_provide?.edukula_platform?.long_description ? we_provide?.edukula_platform?.long_description : '')}
                                                    <Link to={`${process.env.REACT_APP_BASE_URL}edukula`} className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out">Learn more</Link>
                                                </div>
                                            </div>
                                            <div className='relative rounded-2xl w-full bg-cover' style={{ backgroundImage: `url(${bag2})` }}>
                                                <div className='relative z-[1] float-left w-full lg:px-8 px-5 py-6'>
                                                    <div className='flex flex-wrap xl:flex-row gap-8 flex-col items-start justify-between pb-3'>
                                                        <img className="w-[205px] float-left" src={we_provide?.smart_lxp_platform?.image} alt="" />
                                                        <h4 className='xl:w-6/12 w-full font-Poppins_SemiBold text-[22px] text-theme_black pb-3'>{we_provide?.smart_lxp_platform?.title}</h4>
                                                    </div>
                                                    {parse(we_provide?.smart_lxp_platform?.long_description ? we_provide?.smart_lxp_platform?.long_description : '')}
                                                    <Link to={`${process.env.REACT_APP_BASE_URL}smart-lxp`} className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out">Learn more</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const EdukulaFeature = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');

    const { slug } = useParams();
    useEffect(() => {
        fetchSection();
        setTimeout(() => {
            if (typeof slug !== 'undefined' && slug !== '' && slug === 'edukula_feature') {
                var element = document.getElementById('edukula_feature');
                element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }, 1100);
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('feature-section/edukula');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <HomeFeatureLoader image={'right'} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <>
                                    {
                                        (typeof slug !== 'undefined' && slug !== '' && slug === 'edukula_feature')
                                            ?
                                            <div className="clear-both mb-10" id='edukula_feature'></div>
                                            :
                                            null
                                    }
                                    <div className='bg-white float-left w-full py-14 overflow-hidden relative' id='edukula_feature'>
                                        <div className='container m-auto px-4'>
                                            <div className='float-left w-full'>
                                                <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-3'>{section?.edukula?.title}</h2>
                                                <p className='text-lg text-theme_black pb-6 font-WorkSans_Regular'>{parse(section?.edukula?.long_description ? section?.edukula?.long_description : '')}</p>
                                            </div>
                                            <div className='clear-both'></div>
                                        </div>
                                        <div className='xl:container m-auto px-4'>
                                            <div className='float-left w-full relative overflow-hidden flex flex-wrap lg:flex-row flex-col gap-10 justify-between items-center'>
                                                <img className="max-w-[500px] lg:w-5/12 w-full float-right lg:order-2" src={section?.edukula?.image} alt={section?.edukula?.title} />
                                                <div className='relative z-[1] lg:w-6/12 w-full float-left font-WorkSans_Regular md:pb-20'>
                                                    <Accordion defaultActiveKey={section?.features[0]?.image} className='edukula'>
                                                        {
                                                            typeof section?.features !== 'undefined' && section?.features !== '' && section?.features.length > 0
                                                                ?
                                                                <>
                                                                    {
                                                                        section?.features.map((feature, index) =>
                                                                            <Accordion.Item key={index} eventKey={feature?.image}>
                                                                                <Accordion.Header className='show'>{feature?.title}</Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    <div className='flex flex-row gap-4 items-center'>
                                                                                        <img className="w-[60px] float-left" src={feature?.image_url} alt={feature?.title} />
                                                                                        <p className="text-lg text-theme_black">{parse(feature?.description ? feature?.description : '')}</p>
                                                                                    </div>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        )
                                                                    }
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </Accordion>
                                                </div>
                                            </div>
                                            <div className='clear-both'></div>
                                            <div className='bg-theme_gray float-left w-full sm:p-10 p-5 rounded-xl flex lg:flex-row flex-col gap-5 justify-between items-center'>
                                                <div className='float-left flex flex-row justify-between items-center sm:gap-10 gap-5'>
                                                    <img className="w-[60px] float-left" src={`${process.env.REACT_APP_FRONT_URL}assets/images/tabicon2.svg`} alt="" />
                                                    <h3 className='text-theme_blue font-Poppins_SemiBold md:text-[24px] text-[20px]'>Features that make <Link to={`${process.env.REACT_APP_BASE_URL}edukula`} className='text-theme_red'>EduKula</Link> stand apart.</h3>
                                                </div>
                                                <div className='float-right'>
                                                    <Link to={`${process.env.REACT_APP_BASE_URL}edukula`} className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out">Learn more</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const SmartLXPFeature = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    const { slug } = useParams();
    useEffect(() => {
        fetchSection();
        setTimeout(() => {
            if (typeof slug !== 'undefined' && slug !== '' && slug === 'smart_feature') {
                var element = document.getElementById('smart_feature');
                element.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }, 1100);
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('feature-section/smart-lxp');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <HomeFeatureLoader image={'left'} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <>
                                    {
                                        (typeof slug !== 'undefined' && slug !== '' && slug === 'smart_feature')
                                            ?
                                            <div className="clear-both mb-10" id='smart_feature'></div>
                                            :
                                            null
                                    }
                                    <div className='bg-theme_gray float-left w-full py-14 overflow-hidden relative'>
                                        <div className='container m-auto px-4'>
                                            <div className='float-left w-full'>
                                                <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-3'>{section?.smart_lxp?.title}</h2>
                                                <p className='text-lg text-theme_black pb-6 font-WorkSans_Regular'>{parse(section?.smart_lxp?.long_description ? section?.smart_lxp?.long_description : '')}</p>
                                            </div>
                                            <div className='clear-both'></div>
                                        </div>
                                        <div className='xl:container m-auto px-4'>
                                            <div className='float-left w-full relative overflow-hidden flex flex-wrap lg:flex-row flex-col gap-10 justify-between items-center'>
                                                <img className="max-w-[500px] lg:w-5/12 w-full float-right" src={section?.smart_lxp?.image} alt={section?.smart_lxp?.title} />
                                                <div className='relative z-[1] lg:w-6/12 w-full float-left font-WorkSans_Regular md:pb-20'>
                                                    <Accordion defaultActiveKey={section?.features[0]?.image} className='smartlxp'>
                                                        {
                                                            typeof section?.features !== 'undefined' && section?.features !== '' && section?.features.length > 0
                                                                ?
                                                                <>
                                                                    {
                                                                        section?.features.map((feature, index) =>
                                                                            <Accordion.Item key={index} eventKey={feature?.image}>
                                                                                <Accordion.Header className='show'>{feature?.title}</Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    <div className='flex flex-row gap-4 items-center'>
                                                                                        <img className="w-[60px] float-left" src={feature?.image_url} alt={feature?.title} />
                                                                                        <p className="text-lg text-theme_black">{parse(feature?.description ? feature?.description : '')}</p>
                                                                                    </div>
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        )
                                                                    }
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </Accordion>
                                                </div>
                                            </div>
                                            <div className='clear-both'></div>
                                            <div className='bg-white float-left w-full sm:p-10 p-5 rounded-xl flex lg:flex-row flex-col gap-5 justify-between items-center'>
                                                <div className='float-left flex flex-row justify-between items-center sm:gap-10 gap-5'>
                                                    <img className="w-[60px] float-left" src={`${process.env.REACT_APP_FRONT_URL}assets/images/tabicon2.svg`} alt="" />
                                                    <h3 className='text-theme_blue font-Poppins_SemiBold md:text-[24px] text-[20px]'>Features that make <Link to={`${process.env.REACT_APP_BASE_URL}smart-lxp`} className='text-theme_red'>Smart LXP</Link> stand apart.</h3>
                                                </div>
                                                <div className='float-right'>
                                                    <Link to={`${process.env.REACT_APP_BASE_URL}smart-lxp`} className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out">Learn more</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const AboutSection = () => {
    const [processing, setProcessing] = useState(false);
    const [about, setAbout] = useState('');
    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/about-smartvisibility');
        if (res.response) {
            setProcessing(false);
            setAbout(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <AboutSectionLoader />
                    :
                    <>
                        {
                            about !== '' && about !== null
                                ?
                                <div className='bg-white float-left w-full pt-14 overflow-hidden relative'>
                                    <div className='container m-auto px-4 relative z-[1] flex lg:flex-row flex-col lg:gap-5 justify-between items-center'>
                                        <div className='float-right lg:w-6/12 w-full lg:order-2 pb-14'>
                                            <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-3'>{about?.title}</h2>
                                            {parse(about?.short_description ? about?.short_description : '')}
                                            <Link to={`${process.env.REACT_APP_BASE_URL}about-us`} className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out">Read more</Link>
                                        </div>
                                        <div className='float-left lg:w-6/12 w-full'>
                                            <img className="block m-auto" src={about?.image} alt="" />
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const SecondBanner = () => {
    const [processing, setProcessing] = useState(false);
    const [banner, setBanner] = useState('');
    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-banners/your-organization-deserves-the-right');
        if (res.response) {
            setProcessing(false);
            setBanner(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                banner !== '' && banner !== null
                    ?
                    <div className='float-left w-full overflow-hidden relative bg-theme_gray'>
                        <div className='xl:container m-auto px-4 relative'>
                            <div className='float-left w-full rounded-xl md:px-10 px-5 bg-no-repeat bg-cover pt-6 flex lg:flex-row flex-col gap-5 justify-between items-center' style={{ backgroundImage: `url(${bag3})` }}>
                                <div className='pb-6'>
                                    {parse(banner?.long_description ? banner?.long_description : '')}
                                    <Link to={`${process.env.REACT_APP_BASE_URL}contact-us`} className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out">Contact us</Link>
                                </div>
                                <img className="max-w-full w-[340px] md:mr-14 float-right" src={banner?.image_url} alt="" />
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>
                    :
                    null
            }
        </>
    )
}

// checked
export const Blogs = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    const [blogs, setBlogs] = useState('');
    useEffect(() => {
        fetchSection();
        fetchBlogs();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/latest-blog-articles');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    const fetchBlogs = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-blogs?search&is_pagination=1&page=1&length=3');
        if (res.response) {
            setProcessing(false);
            setBlogs(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <BlogSectionLoader count={3} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <div className='bg-white float-left w-full py-14 overflow-hidden'>
                                    <div className='container m-auto px-4'>
                                        <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-3'>{section?.title}</h2>
                                        <p className='text-lg text-theme_black pb-10 font-WorkSans_Regular'>
                                            {parse(section?.short_description ? section?.short_description : '')}
                                        </p>
                                        <div className='clear-both'></div>
                                    </div>
                                    <div className='container m-auto px-4'>
                                        {
                                            typeof blogs?.data !== 'undefined' && blogs?.data !== '' && blogs?.data !== null
                                                ?
                                                <>
                                                    <div className='float-left w-full grid lg:grid-cols-3 sm:grid-cols-2 gap-6 items-start justify-between pt-3'>
                                                        {
                                                            blogs?.data.map((blog, index) =>
                                                                <div key={index} className='float-left w-full'>
                                                                    <div className='float-left w-full h-[200px] overflow-hidden rounded-xl relative'>
                                                                        <Link to={`${process.env.REACT_APP_BASE_URL}blog-detail/${blog?.url}`} className="absolute left-0 right-0 bottom-0 top-0"></Link>
                                                                        <img className='float-left w-full h-full object-cover' src={blog?.image_url} alt={blog?.title} />
                                                                    </div>
                                                                    <div className='float-left w-full font-WorkSans_Regular pt-5'>
                                                                        <p className='text-sm text-[#878787]'>{blog?.formatted_date}</p>
                                                                        <h3 className='text-[18px] font-Poppins_SemiBold py-3'>
                                                                            <Link to={`${process.env.REACT_APP_BASE_URL}blog-detail/${blog?.url}`} className="hover:text-theme_red text-theme_blue">{blog?.title}</Link>
                                                                        </h3>
                                                                        <p className='text-lg text-theme_black pb-5'>{parse(blog?.long_desc ? blog?.long_desc : '')}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                    {
                                                        typeof blogs?.total !== 'undefined' && blogs?.total !== '' && blogs?.total > 3
                                                            ?
                                                            <div className='float-left w-full relative sm:block hidden text-center pt-10'>
                                                                <div className='relative z-[1]'>
                                                                    <Link to={`${process.env.REACT_APP_BASE_URL}blog-listing`} className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out">View all</Link>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </>
                                                :
                                                <p className='font-Poppins_SemiBold'>No blogs found!</p>
                                        }
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }

        </>
    )
}

// checked
export const Recognised = () => {
    const [processing, setProcessing] = useState(false);
    const [brands, setBrands] = useState('');
    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('recognised_by?search&is_pagination=1&page=1&length=3');
        if (res.response) {
            setProcessing(false);
            setBrands(res?.data?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                brands !== '' && brands !== null
                    ?
                    <div className='bg-theme_gray float-left w-full py-14 overflow-hidden'>
                        <div className='container m-auto px-4'>
                            <div className='float-left w-full flex flex-wrap lg:flex-row flex-col gap-10 items-center justify-between pt-3'>
                                <div className='float-left'>
                                    <h2 className='font-Poppins_SemiBold text-[24px] text-theme_blue pb-3'>Recognised by</h2>
                                    <span className='w-[60px] block bg-theme_red h-[2px]'></span>
                                </div>
                                <div className='bg-white float-left lg:w-9/12 w-full py-6 rounded-xl flex flex-wrap md:flex-row flex-col gap-5 items-center justify-between'>
                                    {
                                        brands.map((brand, index) =>
                                            <img key={index} className='float-left max-w-[90%]' src={brand?.image_url} alt={brand?.name} />
                                        )
                                    }
                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>
                    :
                    null
            }
        </>
    )
}