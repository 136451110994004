import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as GeneralService from '../../../services/general.service';
import parse from 'html-react-parser';
import { BannerLoader, CardsLoader } from 'components/interfaces/SkeletonLoader';
import { Pagination } from 'components/plugins';

// checked
export const Banner = () => {
    const [processing, setProcessing] = useState(false);
    const [banner, setBanner] = useState('');
    useEffect(() => {
        fetchBanner();
    }, []);

    const fetchBanner = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-banners/blogs-news');
        if (res.response) {
            setProcessing(false);
            setBanner(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <BannerLoader />
                    :
                    <>
                        {
                            banner !== '' && banner !== null
                                ?
                                <div className='float-left w-full min-h-[500px] lg:pt-0 pt-10 flex flex-wrap items-center relative overflow-hidden'>
                                    <div className='xl:container container m-auto px-4 relative z-[1]'>
                                        <div className='flex lg:flex-row flex-col items-center'>
                                            <div className='xl:w-7/12 lg:w-6/12 w-full md:text-left text-center'>
                                                {parse(banner?.long_description ? banner?.long_description : '')}
                                            </div>
                                            <div className='xl:w-5/12 lg:w-6/12 w-full'>
                                                <img className="md:h-[500px] object-cover lg:float-right m-auto" src={banner?.image_url} alt={banner?.title} />
                                            </div>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const Blogs = () => {
    const [processing, setProcessing] = useState(false);
    const [blogs, setBlogs] = useState('');
    const [page, setPage] = useState(1);
    const [length, setLength] = useState(8);
    const [current_page, setCurrentPage] = useState(0);
    const [total_count, setTotalCount] = useState(0);
    const [last_page, setLastPage] = useState(0);
    useEffect(() => {
        fetchBlogs();
    }, [page, length]);

    const fetchBlogs = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI(`get-blogs?length=${length}&page=${page}&is_pagination=1`);
        if (res.response) {
            setProcessing(false);
            setBlogs(res?.data.data);
            setLastPage(res.data.last_page);
            setTotalCount(res.data.total);
            setCurrentPage(res.data.current_page);
        }
        else {
            setProcessing(false);
        }
    }

    const setScroll = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
          })
    }

    return (
        <>
            {
                processing
                    ?
                    <CardsLoader count={3} title={false} />
                    :
                    <>
                        {
                            typeof blogs != 'undefined' && blogs !== '' && blogs !== null
                                ?
                                <div className='bg-theme_gray float-left w-full py-14 overflow-hidden'>
                                    <div className='container m-auto px-4'>
                                        <div className='float-left w-full grid lg:grid-cols-3 sm:grid-cols-2 gap-6 items-start justify-between'>
                                            {
                                                blogs.map((blog, index) =>
                                                    <div key={index} className='float-left w-full'>
                                                        <div className='float-left w-full h-[200px] overflow-hidden rounded-xl relative'>
                                                            <Link to={`${process.env.REACT_APP_BASE_URL}blog-detail/${blog?.url}`} className="absolute left-0 right-0 bottom-0 top-0"></Link>
                                                            <img className='float-left w-full h-full object-cover' src={blog?.image_url} alt={blog?.title} />
                                                        </div>
                                                        <div className='float-left w-full font-WorkSans_Regular pt-5'>
                                                            <p className='text-sm text-[#878787]'>{blog?.formatted_date}</p>
                                                            <h3 className='text-[18px] font-Poppins_SemiBold py-3'>
                                                                <Link to={`${process.env.REACT_APP_BASE_URL}blog-detail/${blog?.url}`} className="hover:text-theme_red text-theme_blue">{blog?.title}</Link>
                                                            </h3>
                                                            <p className='text-lg text-theme_black pb-5'>{parse(blog?.long_desc ? blog?.long_desc : '')}</p>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="float-left w-full flex items-center justify-center text-center pt-10">
                                            {
                                                last_page > 1
                                                &&
                                                <Pagination totalCount={total_count} currentPage={current_page - 1} perPageRecord={length} handlePageClick={(data) => { setPage(data.selected + 1); setScroll(); }} />
                                            }
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}