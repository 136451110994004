import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Link } from 'react-router-dom';

export const BlockLoader = ({ width, height }) => {
    return (
        <div className='bg-theme_gray float-left w-full py-14 overflow-hidden'>
            <div className='xl:container m-auto px-4'>
                <h2 className='text-theme_red md:text-[32px] text-[26px] font-Poppins_SemiBold pb-3'></h2>
                <div className='grid md:grid-cols-2 lg:gap-16 gap-10 pt-8'>
                    <Skeleton width={width} height={height} borderRadius={20} />
                </div>
                <div className='clear-both'></div>
            </div>
        </div>
    )
}

export const HomeBannerLoader = () => {
    return (
        <div className='float-left w-full lg:min-h-[450px] min-h-[400px] md:pt-0 pt-10 flex flex-wrap items-center banner_bg relative overflow-hidden'>
            <div className='xl:container container m-auto px-4 relative z-[1]'>
                <div className='flex md:flex-row flex-col items-center'>
                    <div className='xl:w-8/12 md:w-6/12 w-full md:text-left text-center'>
                        <Skeleton width={600} height={40} />
                        <Skeleton width={300} height={40} /><br />
                        <Skeleton width={150} height={50} />
                    </div>
                    <div className='xl:w-4/12 md:w-6/12 w-full'>
                        <Skeleton width={500} height={300} borderRadius={50} />
                    </div>
                </div>
                <div className='clear-both'></div>
            </div>
        </div>
    )
}

export const BannerLoader = () => {
    return (
        <div className='float-left w-full min-h-[600px] lg:pt-0 pt-10 flex flex-wrap items-center relative overflow-hidden'>
            <div className='xl:container container m-auto px-4 relative z-[1]'>
                <div className='flex lg:flex-row flex-col items-center'>
                    <div className='xl:w-6/12 lg:w-6/12 w-full md:text-left text-center'>
                        <Skeleton width={600} height={40} />
                        <Skeleton width={300} /><br />
                        <Skeleton width={300} /><br />
                        <Skeleton width={300} /><br />
                        <Skeleton width={150} height={50} />
                    </div>
                    <div className='xl:w-6/12 lg:w-6/12 w-full'>
                        <Skeleton width={550} height={300} borderRadius={30} />
                    </div>
                </div>
                <div className='clear-both'></div>
            </div>
        </div>
    )
}

export const AboutSectionLoader = () => {
    return (
        <div className='bg-white float-left w-full pt-14 overflow-hidden relative'>
            <div className='container m-auto px-4 relative z-[1] flex lg:flex-row flex-col lg:gap-5 justify-between items-center'>
                <div className='float-right lg:w-6/12 w-full lg:order-2 pb-14'>
                    <Skeleton width={250} height={20} /><br />
                    <Skeleton width={500} />
                    <Skeleton width={500} />
                    <Skeleton width={500} />
                    <Skeleton width={500} />
                    <Skeleton width={150} height={50} />
                </div>
                <div className='float-left lg:w-6/12 w-full'>
                    <Skeleton width={500} height={300} borderRadius={50} />
                </div>
                <div className='clear-both'></div>
            </div>
        </div>
    )
}

export const BlogSectionLoader = ({ count }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    return (
        <>
            <div className='bg-white float-left w-full py-14 overflow-hidden'>
                <div className='container m-auto px-4'>
                    <Skeleton width={370} height={30} /><br />
                    <Skeleton width={1200} />
                    <Skeleton width={1200} />
                    <Skeleton width={600} />
                    <div className='clear-both'></div>
                </div>
                <div className='container m-auto px-4'>
                    <div className='float-left w-full grid lg:grid-cols-3 sm:grid-cols-2 gap-6 items-start justify-between pt-3'>
                        {
                            tempArray.map((q, index) => (
                                <div key={index} className='float-left w-full'>
                                    <Skeleton width={400} height={180} borderRadius={10} />
                                    <div className='float-left w-full font-WorkSans_Regular pt-5'>
                                        <Skeleton width={150} />
                                        <Skeleton width={380} height={25} />
                                        <Skeleton width={150} height={25} />
                                        <Skeleton width={380} />
                                        <Skeleton width={380} />
                                        <Skeleton width={380} />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className='float-left w-full relative sm:block hidden text-center pt-10'>
                        <div className='relative z-[1]'>
                            <Skeleton width={150} height={50} />
                        </div>
                    </div>
                    <div className='clear-both'></div>
                </div>
            </div>
        </>
    )
}

export const BlogLoader = () => {
    return (
        <div className='bg-theme_gray float-left w-full py-14'>
            <div className='container m-auto px-4'>
                <div className='float-left w-full flex gap-16 lg:flex-row flex-col items-start'>
                    <div className='float-left lg:w-8/12 w-full blog_detail'>
                        <Skeleton width={800} height={400} /><br />
                        <div className='clear-both'></div>
                        <Skeleton width={200} /><br />
                        <Skeleton width={800} height={25} />
                        <Skeleton width={400} height={25} /><br />
                        <Skeleton width={800} />
                        <Skeleton width={800} />
                        <Skeleton width={800} />
                        <Skeleton width={400} />
                        <Skeleton width={800} />
                        <Skeleton width={800} />
                        <Skeleton width={800} />
                        <Skeleton width={400} />
                    </div>
                    <div className='float-left lg:w-4/12 w-full sticky_position'>
                        <h3 className='font-Poppins_SemiBold text-theme_red md:text-[24px] text-[20px] pb-3'>Latest post</h3>
                        <ul className='float-left w-full'>
                            <li className='text-lg py-2 pl-4 relative text-theme_black leading-6 font-WorkSans_Regular'>
                                <Skeleton count={1} />
                                <Skeleton width={200} />
                            </li>
                            <li className='text-lg py-2 pl-4 relative text-theme_black leading-6 font-WorkSans_Regular'>
                                <Skeleton count={1} />
                                <Skeleton width={200} />
                            </li>
                            <li className='text-lg py-2 pl-4 relative text-theme_black leading-6 font-WorkSans_Regular'>
                                <Skeleton count={1} />
                                <Skeleton width={200} />
                            </li>
                            <li className='text-lg py-2 pl-4 relative text-theme_black leading-6 font-WorkSans_Regular'>
                                <Skeleton count={1} />
                                <Skeleton width={200} />
                            </li>
                            <li className='text-lg py-2 pl-4 relative text-theme_black leading-6 font-WorkSans_Regular'>
                                <Skeleton count={1} />
                                <Skeleton width={200} />
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='clear-both'></div>
            </div>
        </div>
    )
}

export const ListLoader = ({ count }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    return (
        <div className='float-left lg:w-4/12 w-full sticky_position'>
            <h3 className='font-Poppins_SemiBold text-theme_red md:text-[24px] text-[20px] pb-3'>Latest post</h3>
            <ul className='float-left w-full'>
                {
                    tempArray.map((q, index) => (
                        <li key={index} className='text-lg py-2 pl-4 relative text-theme_black leading-6 font-WorkSans_Regular'>
                            <Skeleton count={1} />
                            <Skeleton width={200} />
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export const HomeFeatureLoader = ({ image, showcta = true }) => {
    return (
        <div className='bg-white float-left w-full py-14 overflow-hidden relative'>
            <div className='container m-auto px-4'>
                <div className='float-left w-full'>
                    <Skeleton width={200} height={30} borderRadius={20} />
                    <Skeleton width={1200} borderRadius={20} />
                    <Skeleton width={800} borderRadius={20} />
                </div>
                <div className='clear-both'></div>
            </div><br /><br />
            <div className='xl:container m-auto px-4'>
                <div className='float-left w-full relative overflow-hidden flex flex-wrap lg:flex-row flex-col gap-10 justify-between items-center'>
                    {
                        image === 'right'
                            ?
                            <>
                                <Skeleton width={550} height={600} borderRadius={20} />
                                <div className='relative z-[1] lg:w-6/12 w-full float-left font-WorkSans_Regular md:pb-20'>
                                    <Skeleton width={500} height={500} borderRadius={300} />
                                </div>
                            </>
                            :
                            <>
                                <Skeleton width={500} height={500} borderRadius={300} />
                                <div className='relative z-[1] lg:w-6/12 w-full float-left font-WorkSans_Regular md:pb-20'>
                                    <Skeleton width={550} height={600} borderRadius={20} />
                                </div>
                            </>
                    }
                </div>
                <div className='clear-both'></div>
                {
                    showcta
                        ?
                        <div className='bg-theme_gray float-left w-full sm:p-10 p-5 rounded-xl flex lg:flex-row flex-col gap-5 justify-between items-center'>
                            <Skeleton width={1100} height={150} borderRadius={20} />
                        </div>
                        :
                        null
                }
            </div>
        </div>
    )
}

export const LXPCmsLoader = ({ count }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    return (
        <div className='bg-white float-left w-full py-14 overflow-hidden relative'>
            <div className='container m-auto px-4'>
                <div className='float-left w-full'>
                    <Skeleton width={200} height={30} borderRadius={20} />
                    <Skeleton width={1200} borderRadius={20} />
                    <Skeleton width={800} borderRadius={20} />
                </div>
                <div className='clear-both'></div>
            </div><br /><br />
            <div className='xl:container m-auto px-4'>
                <div className='float-left w-full relative overflow-hidden flex flex-wrap lg:flex-row flex-col gap-10 justify-between items-center'>
                    {
                        tempArray.map((q, index) => (
                            <div key={index}>
                                <Skeleton width={1200} height={350} borderRadius={20} />
                            </div>
                        ))
                    }
                </div>
                <div className='clear-both'></div>
            </div>
        </div>
    )
}

export const CardsLoader = ({ count, title = true }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }

    return (
        <div className='bg-white float-left w-full py-14'>
            <div className='container m-auto px-4'>
                <div className='float-left w-full'>
                    {
                        title
                            ?
                            <>
                                <Skeleton width={250} height={30} /><br />
                                <Skeleton width={1200} /><br />
                            </>
                            :
                            null
                    }

                    <div className='float-left w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 items-start justify-between pt-3'>
                        {
                            tempArray.map((q, index) => (
                                <div key={index} className='float-left w-full'>
                                    <Skeleton width={400} height={300} borderRadius={10} />
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className='clear-both'></div>
            </div>
        </div>
    )
}

export const FeatureSectionLoader = ({ count }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    return (
        <>
            <div className='bg-white float-left w-full py-14 overflow-hidden relative'>
                <div className='container m-auto px-4'>
                    <div className='float-left w-full pb-3'>
                        <Skeleton width={380} height={30} /><br />
                        <Skeleton width={1100} />
                        <Skeleton width={500} />
                    </div>
                    <div className='float-left w-full grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 items-center justify-between pt-3 pb-10'>
                        {
                            tempArray.map((q, index) => (
                                <div key={index} >
                                    <Skeleton width={200} height={80} />
                                </div>
                            ))
                        }

                    </div>
                    <div className='float-left w-full text-center pt-3'>
                        <Skeleton width={150} height={60} />
                    </div>
                    <div className='clear-both'></div>
                </div>
            </div>
        </>
    )
}




export const CmsBannerLoader = ({ count, image }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <>
                    <div className='bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${image})` }}>
                        <div className='absolute z-[1] m-auto inset-y-2/4 w-full flex items-center h-fit md:pt-[91px]'>
                            <div className='container m-auto px-4'>
                                <div className='flex justify-between items-center'>
                                    <div className='lg:w-auto md:w-6/12 w-full'>
                                        <Skeleton width={300} height={30} baseColor="#ddd" highlightColor="#eee" />
                                        <Skeleton width={300} baseColor="#ddd" highlightColor="#eee" />
                                        <Skeleton width={300} baseColor="#ddd" highlightColor="#eee" />
                                        <Skeleton width={250} baseColor="#ddd" highlightColor="#eee" />
                                        <br />
                                        <Skeleton width={150} height={40} borderRadius={50} baseColor="#ddd" highlightColor="#eee" />
                                        <div className="clear-both"></div>
                                    </div>
                                    <div className='lg:w-auto md:w-6/12 w-full md:block hidden'>
                                        <Skeleton width={500} height={300} borderRadius={50} baseColor="#ddd" highlightColor="#eee" />
                                    </div>
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </div>
                    </div>
                </>
            ))
        )
    }
}

export const CmsContentLoader = ({ count, image }) => {
    const tempArray = [];
    for (let i = 0; i < count; i++) {
        tempArray[i] = i;
    }
    {
        return (
            tempArray.map((q, index) => (
                <>
                    <div className='bg-white h-[500px] md:h-[600px] relative overflow-hidden bg-no-repeat bg-center bg-cover' style={{ backgroundImage: `url(${image})` }}>
                        <div className='absolute z-[1] m-auto inset-y-2/4 w-full flex items-center h-fit md:pt-[91px]'>
                            <div className='container m-auto px-4'>
                                <div className='flex justify-between items-center'>
                                    <div className='lg:w-auto md:w-6/12 w-full'>
                                        <Skeleton width={300} height={30} baseColor="#ddd" highlightColor="#eee" />
                                        <Skeleton width={300} baseColor="#ddd" highlightColor="#eee" />
                                        <Skeleton width={300} baseColor="#ddd" highlightColor="#eee" />
                                        <Skeleton width={250} baseColor="#ddd" highlightColor="#eee" />
                                        <br />
                                        <Skeleton width={150} height={40} borderRadius={50} baseColor="#ddd" highlightColor="#eee" />
                                        <div className="clear-both"></div>
                                    </div>
                                    <div className='lg:w-auto md:w-6/12 w-full md:block hidden'>
                                        <Skeleton width={500} height={300} borderRadius={50} baseColor="#ddd" highlightColor="#eee" />
                                    </div>
                                </div>
                                <div className="clear-both"></div>
                            </div>
                        </div>
                    </div>
                </>
            ))
        )
    }
}