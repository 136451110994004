import React, { } from 'react';
import { Content } from './components/CMSSections';

const CMSPage = () => {
    return (
        <>
            <Content />
            <div className='clear-both'></div>

        </>
    )
};

export default CMSPage;