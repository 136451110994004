import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as GeneralService from '../../../services/general.service';
import parse from 'html-react-parser';

const bag1 = `${process.env.REACT_APP_BASE_URL}assets/images/about-us-slider.jpg`;

// checked
export const Content = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    const [sitemap, setSitemap] = useState(false);

    const { slug } = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        if (typeof slug === 'undefined' && slug === '') {
            navigate('/');
        }
        fetchSection();
    }, []);

    const fetchSection = async () => {
        var url = `get-cms/${slug}`;
        if (slug === 'sitemap') {
            setSitemap(true);
            url = `get-sitemap`;
        }
        const res = await GeneralService.GetContentAPI(url);
        if (res?.data?.title === 'Page Not Found') {
            navigate('404');
        }
        if (res.response) {
            setSection(res?.data);
        }
        else {
        }
    }

    return (
        <>
            <div className='bg-white float-left w-full py-14 overflow-hidden relative'>
                <div className='container m-auto px-4'>
                    <div className='float-left w-full pb-3'>
                        <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-3'>{sitemap ? 'Sitemap' : section?.title}</h2>
                        {
                            sitemap
                                ?
                                <>
                                    {
                                        section?.headerMenu?.length !== '' && section?.headerMenu?.length > 0
                                            ?
                                            <div className='float-left lg:w-6/12 w-full'>
                                                {/* API generated Site Map */}

                                                {/* <ul className='float-left w-full'>
                                                    {
                                                        section?.headerMenu.map((page, index) =>
                                                            <li key={index} className="float-left w-full relative pl-[15px] pb-4">
                                                                <i className="fa-solid fa-circle-small absolute left-0 text-[10px] top-[10px]"></i>
                                                                <a className='text-lg text-theme_blue hover:text-theme_orange' href={`${process.env.REACT_APP_BASE_URL}${page?.url}`}>
                                                                    {page?.title}
                                                                </a>
                                                                {
                                                                    page?.child?.length > 0 && page?.child?.length !== ''
                                                                        ?
                                                                        <ul className='float-left w-full'>
                                                                            {
                                                                                page?.child.map((innerPage, key) =>
                                                                                    <li key={key} className="float-left w-full relative pl-[25px] pb-1">
                                                                                        <a className='text-lg text-theme_blue hover:text-theme_orange' href={`${process.env.REACT_APP_BASE_URL}${innerPage?.url}`}>
                                                                                            {innerPage?.title}
                                                                                        </a>
                                                                                    </li>
                                                                                )
                                                                            }
                                                                        </ul>
                                                                        :
                                                                        null
                                                                }
                                                            </li>
                                                        )
                                                    }
                                                </ul> */}

                                                {/* Manual Site Map */}
                                                <ul className='float-left w-full'>
                                                    <li className="float-left w-full relative pl-[15px] pb-4">
                                                        <i className="fa-solid fa-circle-small absolute left-0 text-[10px] top-[10px]"></i>
                                                        <a className='text-lg text-theme_blue hover:text-theme_orange' href={`/about-us`}>
                                                            About us
                                                        </a>
                                                    </li>
                                                    <li className="float-left w-full relative pl-[15px] pb-4">
                                                        <i className="fa-solid fa-circle-small absolute left-0 text-[10px] top-[10px]"></i>
                                                        <a className='text-lg text-theme_blue hover:text-theme_orange' href={`/blog-listing`}>
                                                            Blogs
                                                        </a>
                                                    </li>
                                                    <li className="float-left w-full relative pl-[15px] pb-4">
                                                        <i className="fa-solid fa-circle-small absolute left-0 text-[10px] top-[10px]"></i>
                                                        <a className='text-lg text-theme_blue hover:text-theme_orange'>
                                                            Products and Services
                                                        </a>
                                                        <ul className='float-left w-full'>
                                                            <li className="float-left w-full relative pl-[25px] pb-1">
                                                                <a className='text-lg text-theme_blue hover:text-theme_orange' href={`/edukula`}>
                                                                    EduKula
                                                                </a>
                                                            </li>
                                                            <li className="float-left w-full relative pl-[25px] pb-1">
                                                                <a className='text-lg text-theme_blue hover:text-theme_orange' href={`/smart-lxp`}>
                                                                    Smart LXP
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                    <li className="float-left w-full relative pl-[15px] pb-4">
                                                        <i className="fa-solid fa-circle-small absolute left-0 text-[10px] top-[10px]"></i>
                                                        <a className='text-lg text-theme_blue hover:text-theme_orange' href={`/free-trial`}>
                                                            Free Trial
                                                        </a>
                                                    </li>
                                                    <li className="float-left w-full relative pl-[15px] pb-4">
                                                        <i className="fa-solid fa-circle-small absolute left-0 text-[10px] top-[10px]"></i>
                                                        <a className='text-lg text-theme_blue hover:text-theme_orange' href={`/contact-us`}>
                                                            Contact us
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        section?.footerMenu?.length !== '' && section?.footerMenu?.length > 0
                                            ?
                                            <div className='float-left lg:w-6/12 w-full'>

                                                {/* API generated Site Map */}
                                                {/* <ul className='float-left w-full'>
                                                    {
                                                        section?.footerMenu.map((page, index) =>
                                                            <li key={index} className="float-left w-full relative pl-[15px] pb-4">
                                                                <i className="fa-solid fa-circle-small absolute left-0 text-[10px] top-[10px]"></i>
                                                                <a className='text-lg text-theme_blue hover:text-theme_orange' href={`${process.env.REACT_APP_BASE_URL}${page?.url}`}>
                                                                    {page?.title}
                                                                </a>
                                                            </li>
                                                        )
                                                    }
                                                </ul> */}

                                                {/* Manual Site Map */}
                                                <ul className='float-left w-full'>
                                                    <li className="float-left w-full relative pl-[15px] pb-4">
                                                        <i className="fa-solid fa-circle-small absolute left-0 text-[10px] top-[10px]"></i>
                                                        <a className='text-lg text-theme_blue hover:text-theme_orange' href={`/accessibility`}>
                                                            Accessibility
                                                        </a>
                                                    </li>
                                                    <li className="float-left w-full relative pl-[15px] pb-4">
                                                        <i className="fa-solid fa-circle-small absolute left-0 text-[10px] top-[10px]"></i>
                                                        <a className='text-lg text-theme_blue hover:text-theme_orange' href={`/privacy-statement`}>
                                                            Privacy Statement
                                                        </a>
                                                    </li>
                                                    <li className="float-left w-full relative pl-[15px] pb-4">
                                                        <i className="fa-solid fa-circle-small absolute left-0 text-[10px] top-[10px]"></i>
                                                        <a className='text-lg text-theme_blue hover:text-theme_orange' href={`/terms-of-use`}>
                                                            Terms and Conditions
                                                        </a>
                                                    </li>
                                                    <li className="float-left w-full relative pl-[15px] pb-4">
                                                        <i className="fa-solid fa-circle-small absolute left-0 text-[10px] top-[10px]"></i>
                                                        <a className='text-lg text-theme_blue hover:text-theme_orange' href={`/cookie-policy`}>
                                                            Cookie Policy
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            :
                                            null
                                    }
                                </>
                                :
                                <div className='text-lg text-theme_black pb-6 font-WorkSans_Regular cms_pages'>{parse(section?.long_description ? section?.long_description : 'No content yet!')}</div>
                        }
                    </div>
                    <div className='clear-both'></div>
                </div>
            </div>
        </>

    )
}