import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as GeneralService from '../../../services/general.service';
import parse from 'html-react-parser';
import { FeatureSectionLoader, BannerLoader, LXPCmsLoader } from 'components/interfaces/SkeletonLoader';
import landingInIt from '../landing';

const bag1 = `${process.env.REACT_APP_BASE_URL}assets/images/home_btn_bg.jpg`;

// checked
export const Banner = () => {
    const [processing, setProcessing] = useState(false);
    const [banner, setBanner] = useState('');
    useEffect(() => {
        fetchBanner();
    }, []);

    const fetchBanner = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-banners/smart-lxp-banner');
        if (res.response) {
            setProcessing(false);
            setBanner(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <BannerLoader />
                    :
                    <>
                        {
                            banner !== '' && banner !== null
                                ?
                                <div className='float-left w-full min-h-[600px] lg:pt-0 pt-10 flex flex-wrap items-center relative overflow-hidden'>
                                    <div className='xl:container container m-auto px-4 relative z-[1]'>
                                        <div className='flex lg:flex-row flex-col items-center'>
                                            <div className='xl:w-6/12 lg:w-6/12 w-full md:text-left text-center'>
                                                {parse(banner?.long_description ? banner?.long_description : '')}
                                                <Link to={`${process.env.REACT_APP_BASE_URL}free-trial`} className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out">Get started</Link>
                                            </div>
                                            <div className='xl:w-6/12 lg:w-6/12 w-full'>
                                                <img className="md:h-[600px] object-cover lg:float-right m-auto" src={banner?.image_url} alt={banner?.title} />
                                            </div>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const LearningPlatform = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    const [innerOne, setInnerOne] = useState('');
    const [innerTwo, setInnerTwo] = useState('');
    useEffect(() => {
        fetchSection();
        fetchInnerOne();
        fetchInnerTwo();
        setTimeout(() => {
            landingInIt();
        }, 1500)
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/a-multifaceted-learning-platform');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    const fetchInnerOne = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/corporate-organizations');
        if (res.response) {
            setProcessing(false);
            setInnerOne(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    const fetchInnerTwo = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/educational-institutes');
        if (res.response) {
            setProcessing(false);
            setInnerTwo(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <LXPCmsLoader count={1} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <div className="desktop-only bg-theme_gray float-left w-full py-14 overflow-hidden relative">
                                    <div className='container m-auto px-4'>
                                        <div className='float-left w-full'>
                                            <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-3'>{section?.title}</h2>
                                            {parse(section?.short_description ? section?.short_description : '')}
                                        </div>
                                        <div className="feature-det-cont float-left w-full" id="ft-id-1">
                                            <div className="flex-box">
                                                <div className="ft-right-cont">
                                                    <div className="feature-right-img-cont custom-feedback-box-shadow">
                                                        <div id="testimonial-carousel" className="h-full w-full">
                                                            <div className="carousel-wrapper h-full w-full">
                                                                <div className="carousel h-full w-full flex justify-content">
                                                                    <div className="sm-1-carousel-slide-item active">
                                                                        <div className="">
                                                                            {
                                                                                typeof innerOne !== 'undefined' && innerOne !== '' && innerOne !== null
                                                                                    ?
                                                                                    <img src={innerOne?.image} alt="" loading="lazy" />
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="sm-1-carousel-slide-item">
                                                                        <div className="">
                                                                            {
                                                                                typeof innerTwo !== 'undefined' && innerTwo !== '' && innerTwo !== null
                                                                                    ?
                                                                                    <img src={innerTwo?.image} alt="" loading="lazy" />
                                                                                    :
                                                                                    null
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    <div className="sm-1-carousel-slide-item !hidden"></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ft-left-cont">
                                                    <div className="relative">
                                                        <div className="text-left">
                                                            <div id="sm-1" className="cursor-pointer text_line">
                                                                {
                                                                    typeof innerOne !== 'undefined' && innerOne !== '' && innerOne !== null
                                                                        ?
                                                                        <>
                                                                            {parse(innerOne?.short_description ? innerOne?.short_description : '')}
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="text-left">
                                                            <div id="sm-2" className="cursor-pointer text_line">
                                                                {
                                                                    typeof innerTwo !== 'undefined' && innerTwo !== '' && innerTwo !== null
                                                                        ?
                                                                        <>
                                                                            {parse(innerTwo?.short_description ? innerTwo?.short_description : '')}
                                                                        </>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="text-left hidden">
                                                            <div id="sm-3" className="cursor-pointer text_line">
                                                                <div className="mordern-title">
                                                                    <h4>Leaderboard</h4>
                                                                </div>
                                                                <div className="tm-para-16 mordern-para mt-2.5">
                                                                    <p>Get an in-depth overview of the candidate's scores, course completion details, and contact information with our comprehensive leaderboard.</p>
                                                                </div>
                                                                <div className="animation-bar-2">
                                                                    <span></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const LearningExeperience = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/enhance-the-learning-experience');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <LXPCmsLoader count={3} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <div className='bg-white float-left w-full py-14 relative'>
                                    <div className='container m-auto px-4'>
                                        <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-3 sticky_position1 z-[2]'>{section?.title}</h2>
                                        {parse(section?.short_description ? section?.short_description : '')}

                                        {parse(section?.long_description ? section?.long_description : '')}
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const Features = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    const [features, setFeatures] = useState('');
    useEffect(() => {
        fetchSection();
        fetchFeatures();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('feature-section/smart-lxp');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    const fetchFeatures = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-features?category_id=2&search=&is_pagination=1&page=1&length=8');
        if (res.response) {
            setProcessing(false);
            setFeatures(res?.data.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <FeatureSectionLoader count={8} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <div className='bg-theme_gray float-left w-full py-14 overflow-hidden relative'>
                                    <div className='container m-auto px-4'>
                                        <div className='float-left w-full pb-3'>
                                            <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-3'>Features</h2>
                                            <p className='text-lg text-theme_black pb-6 font-WorkSans_Regular'>{parse(section?.smart_lxp?.long_description ? section?.smart_lxp?.long_description : '')}</p>
                                        </div>
                                        <div className='float-left w-full grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 items-center justify-between pt-3 pb-10'>
                                            {
                                                typeof features !== 'undefined' && features !== '' && features !== null
                                                    ?
                                                    <>
                                                        {
                                                            features.map((feature, index) =>
                                                                <div key={index} className='float-left w-full shadow-md shadow-theme_black/6 rounded-lg p-3 font-Poppins_SemiBold text-lg text-theme_blue relative pl-[75px] min-h-[80px] items-center flex'>
                                                                    <img className="w-[50px] absolute left-[10px] top-[10px]" src={feature?.image_url} alt={feature?.title} />
                                                                    {feature?.title}
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className='float-left w-full text-center pt-3'>
                                            <Link to={`${process.env.REACT_APP_BASE_URL}section/smart_feature`} className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block m-auto py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out">Learn more</Link>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }

        </>
    )
}

// checked
export const SecondBanner = () => {
    const [processing, setProcessing] = useState(false);
    const [banner, setBanner] = useState('');
    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-banners/empower-your-organization-by-digitizing-its-education-and-training-program-with-smart-lxp');
        if (res.response) {
            setProcessing(false);
            setBanner(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                banner !== '' && banner !== null
                    ?
                    <div className='float-left w-full overflow-hidden relative bg-theme_gray'>
                        <div className='xl:container m-auto px-4 relative'>
                            <div className='float-left w-full rounded-xl md:px-10 px-5 bg-no-repeat bg-cover pt-6 flex lg:flex-row flex-col gap-5 justify-between items-center' style={{ backgroundImage: `url(${bag1})` }}>
                                <div className='pb-6'>
                                    {parse(banner?.short_description ? banner?.short_description : '')}
                                    <Link to={`${process.env.REACT_APP_BASE_URL}free-trial`} className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out">Sign up</Link>
                                </div>
                                <img className="max-w-full w-[290px] md:mr-14 float-right" src={banner?.image_url} alt={banner?.title} />
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>
                    :
                    null
            }
        </>
    )
}