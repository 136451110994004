import React, { useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Home, EduKula, SmartLXP, AboutUs, ContactUs, BlogListing, BlogDetail, FreeTrial, CMSPage } from 'MdlPages';
import { PageNotFound } from 'ErrorPages';

import authContext from 'contexts/auth/authContext';

/** Middleware to check if user is not logged in */
const PrivateRoute = ({ children }) => {
    const cntxAuth = useContext(authContext);
    return cntxAuth.isLogged ? children : <Navigate to={`${process.env.REACT_APP_BASE_URL}login`} />;
}

/** Middleware to check if user is logged in */
const LoggedRoute = ({ children }) => {
    const cntxAuth = useContext(authContext);
    return cntxAuth.isLogged ? <Navigate to={`${process.env.REACT_APP_BASE_URL}login`} /> : children;
}

const Router = () => {
    return (
        <>
            <Routes basename={`${process.env.REACT_APP_BASE_URL}`}>
                <Route exact path={`${process.env.REACT_APP_BASE_URL}`} element={<Home />} />
                <Route exact path={`${process.env.REACT_APP_BASE_URL}section/:slug`} element={<Home />} />
                <Route exact path={`${process.env.REACT_APP_BASE_URL}edukula`} element={<EduKula />} />
                <Route exact path={`${process.env.REACT_APP_BASE_URL}smart-lxp`} element={<SmartLXP />} />
                <Route exact path={`${process.env.REACT_APP_BASE_URL}about-us`} element={<AboutUs />} />
                <Route exact path={`${process.env.REACT_APP_BASE_URL}free-trial`} element={<FreeTrial />} />
                <Route exact path={`${process.env.REACT_APP_BASE_URL}contact-us`} element={<ContactUs />} />
                <Route exact path={`${process.env.REACT_APP_BASE_URL}blog-listing`} element={<BlogListing />} />
                <Route exact path={`${process.env.REACT_APP_BASE_URL}blog-detail/:slug`} element={<BlogDetail />} />
                <Route exact path={`${process.env.REACT_APP_BASE_URL}:slug`} element={<CMSPage />} />

                {/* ERROR ROUTES */}
                <Route path={`${process.env.REACT_APP_BASE_URL}404`} element={<PageNotFound />} />
                <Route path="*" element={<Navigate replace to={`${process.env.REACT_APP_BASE_URL}404`} />} />
                {/* ERROR ROUTES */}
            </Routes>
        </>
    )
};

export default Router;