import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Banner, ContactCMS, ContactForm } from './components/FreeTrialSections';

const FreeTrial = () => {
    return (
        <>
            {/* <Banner />
            <div className='clear-both'></div>

            <ContactCMS />
            <div className='clear-both'></div> */}

            <ContactForm />
            <div className='clear-both'></div>

        </>
    )
};

export default FreeTrial;