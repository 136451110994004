import React, { useState } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as GeneralService from '../../services/general.service';
import parse from 'html-react-parser';
import Swal from 'sweetalert2';

const Footer = () => {
    const [copyrightYear] = useState(new Date().getFullYear());
    const [processing, setProcessing] = useState(false);
    const [footer_links, setFooterLinks] = useState([]);
    const [social_links, setSocialLinks] = useState([]);
    const [info, setInfo] = useState({});
    const [message, setMessage] = useState({});

    useEffect(() => {
        fetchFooterLinks();
        fetchSocialLinks();
        fetchInfo();
    }, []);

    const fetchInfo = async () => {
        const res = await GeneralService.GetContentAPI('site-info');
        if (res.response) {
            setInfo(res?.data);
        }
        else {
        }
    }

    const fetchFooterLinks = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('footer-links');
        if (res.response) {
            setProcessing(false);
            setFooterLinks(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    const fetchSocialLinks = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('social-links');
        if (res.response) {
            setProcessing(false);
            setSocialLinks(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    const { register, handleSubmit, setError, formState: { errors }, reset } = useForm();
    const saveForm = async (form) => {
        let formData = new FormData();
        formData.append('email', form.email);

        setProcessing(true);
        const res = await GeneralService.PostForm('subscribe', formData);
        if (res.response) {
            reset();
            setProcessing(false);
            if (res?.status) {
                Swal.fire({
                    // title: 'Thank You!',
                    html: '<strong>Thank you!</strong> for subscribing to the Newsletter',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Close'
                }).then((result) => {
                    if (result.isConfirmed) {
                        return null;
                    }
                });
            } else {
                setMessage({ type: 'error', message: res?.message });
            }
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            <div className="clear-both"></div>
            <footer className="bg-[#141414] relative overflow-hidden py-6 text-white pb-6">
                <div className='container m-auto px-4'>
                    <div className='float-left w-full flex lg:flex-row flex-col lg:gap-32 gap-14 items-center justify-between sm:py-6'>
                        <div className='float-left lg:w-4/12 w-full relative'>
                            <div className="flex flex-col">
                                <a href={`${process.env.REACT_APP_BASE_URL}`}>
                                    {/* <img className="w-[180px]" src={info?.logo ? info?.logo : `${process.env.REACT_APP_FRONT_URL}assets/images/logofooter.svg`} alt="" /> */}
                                    <img className="w-[180px]" src={`${process.env.REACT_APP_FRONT_URL}assets/images/logofooter.svg`} alt="" />
                                </a>
                                <p className='font-WorkSans_Regular text-base text-white lg:py-10 py-5 leading-7'>
                                    {
                                        info?.seo?.seo_title
                                            ?
                                            info?.seo?.seo_title
                                            :
                                            <>
                                                Largest edutech company for educational <br />institutes and corporates
                                            </>
                                    }
                                </p>
                                <ul className='flex lg:justify-between gap-4'>
                                    {
                                        typeof social_links !== 'undefined' && social_links.length > 0 && social_links !== ''
                                            ?
                                            <>
                                                {
                                                    social_links.map((link, index) =>
                                                        <li key={index}>
                                                            <a title={link?.link_title} className='w-[36px] text-center leading-9 float-left text-white hover:text-gray-400 text-xl' href={link?.URL} target='_blank'>
                                                                <i className={link?.title}></i>
                                                            </a>
                                                        </li>
                                                    )
                                                }
                                            </>
                                            :
                                            null
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='float-left lg:w-8/12 w-full relative'>
                            <div className='float-left w-full'>
                                <h2 className='text-white md:text-[22px] text-[18px] font-Poppins_SemiBold pb-5'>Get industry insights & news that support your growth</h2>
                                <div className='float-left w-full relative'>
                                    {
                                        typeof message !== 'undefined' && message !== ''
                                            ?
                                            <p className={`${message?.type === 'success' ? 'text-theme_orange' : 'text-theme_red'} font-WorkSans_Regular`}>{parse(message?.message ? message?.message : '')}</p>
                                            :
                                            null
                                    }
                                    <form onSubmit={handleSubmit(saveForm)}>
                                        <input
                                            {...register("email", {
                                                required: "Email is required!",
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "invalid email address!"
                                                }
                                            })}
                                            className='w-full float-left bg-transparent border border-[#3c3c3c] focus:outline-none py-4 px-5 pr-44 rounded-full text-white font-WorkSans_Regular'
                                            type="text"
                                            placeholder='Your e-mail address'
                                        />
                                        <button className="bg-theme_orange font-WorkSans_Medium text-base leading-10 py-[9px] px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out absolute right-0 rounded-full" type='submit' value={''}>Subscribe <i className="fa-light fa-arrow-right"></i></button>
                                        {errors.email && <p className='text-theme_red font-WorkSans_Regular'>{errors.email.message}</p>}
                                    </form>
                                </div>
                            </div>
                            <div className='float-left w-full pt-10'>
                                <h2 className='text-white md:text-[22px] text-[18px] font-Poppins_SemiBold pb-3'>Supports</h2>
                                <p className='text-white text-base inline-block leading-8'>
                                    {
                                        typeof footer_links !== 'undefined' && footer_links.length > 0 && footer_links !== ''
                                            ?
                                            <>
                                                {
                                                    footer_links.map((link, index) =>
                                                        <a key={index} className='md:inline-block text-base font-WorkSans_Regular px-4 text-white hover:text-gray-400 pl-0' href={`${process.env.REACT_APP_BASE_URL}${link?.url}`}> {link?.title} </a>
                                                    )
                                                }
                                            </>
                                            :
                                            null
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="clear-both"></div>
                    <div className='mt-6 float-left w-full border-t border-[#3c3c3c] pt-6'>
                        <p className='text-white font-WorkSans_Regular text-sm text-center'>Copyright &#169; {copyrightYear} {info?.name ? info?.name : 'SmartVisibility inc'}. All rights reserved.</p>
                    </div>
                    <div className="clear-both"></div>
                </div>
                <div className='hidden'>
                    <h1 className="hidden font-Poppins_Bold lg:text-[38px] md:text-[30px] text-[28px] md:max-w-none max-w-[680px] text-theme_blue pb-5 m-auto"></h1>
                    <p className="hidden font-Poppins_SemiBold text-theme_red md:text-[30px] text-[20px] pb-3"></p>
                    <h1 className="hidden font-Poppins_Bold lg:text-[36px] md:text-[30px] text-[24px] text-theme_blue pb-5 m-auto"></h1>
                    <p className="hidden font-WorkSans_Regular text-theme_black text-lg lg:max-w-[600px] pb-8"></p>

                    <h3 className="hidden font-Poppins_SemiBold text-theme_blue md:text-[26px] text-[20px] pb-3"></h3>
                    <a href="#" className="hidden text-lg font-Poppins_SemiBold text-theme_orange"></a>
                    <ul className="w-full float-left hidden">
                        <li className="hidden float-left w-full relative pl-[80px] pb-4"><img className="w-[60px] absolute left-0 top-2 hidden" src="" alt="" />
                            <p className="hidden font-Poppins_SemiBold text-lg text-theme_blue pb-1"></p>
                            <p className="hidden font-WorkSans_Regular text-lg text-theme_black pb-2"></p>
                        </li>
                    </ul>
                    <div className="hidden bg-[#f4faff] float-left rounded-xl md:px-10 px-5 md:py-10 py-5 w-full flex justify-between items-center flex-wrap lg:flex-row flex-col gap-10 mb-12">
                        <div className="hidden float-right lg:w-6/12 w-full relative lg:order-2">
                            <img className="hidden md:w-[500px] object-cover rounded-xl lg:float-right m-auto" src="" alt="" />
                        </div>
                        <div className="hidden float-left lg:w-5/12 w-full">
                            <p className="hidden font-Poppins_SemiBold text-[22px] text-theme_blue pb-5"></p>
                            <p className="hidden font-WorkSans_Regular text-lg text-theme_black pb-2"></p>
                        </div>
                    </div>
                    <i className="text-[10px] absolute left-0 top-[13px] fa-solid fa-circle-small hidden"></i>
                </div>
            </footer>
        </>
    )
};

export default Footer;