import React, { useEffect, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as GeneralService from '../../services/general.service';
import 'flowbite';
import { useState } from 'react';

const CustNavbar = () => {
    const location = useLocation();
    const { t } = useTranslation();
    const [header, setHeader] = useState([]);

    useEffect(() => {
        fetchHeader();
    }, []);

    const fetchHeader = async () => {
        const res = await GeneralService.GetContentAPI('site-info');
        if (res.response) {
            setHeader(res?.data);
        }
        else {
        }
    }

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    const toggleNavbar = (e) => {
        const element = document.getElementById('navbar-dropdown');
        const elementb = document.getElementById('navbar-dropdown-button');

        if (element.classList.contains('hidden')) {
            element.classList.remove("hidden");
            elementb.classList.setAttribute("aria-expanded", "true");
        } else {
            element.classList.add("hidden");
            elementb.classList.setAttribute("aria-expanded", "false");
        }
    }

    const toggleNavDrop = (e) => {
        const element = document.getElementById('dropdownNavbar');
        const elementb = document.getElementById('dropdownNavbarLink');

        if (element.classList.contains('hidden')) {
            element.classList.remove("hidden");
        } else {
            element.classList.add("hidden");
        }
    }

    return (
        <>
            <header className="bg-white shadow-lg shadow-black/10 relative md:sticky md:top-0 z-50 py-2.5 clear-both">
                <nav className="px-2 bg-white">
                    <div className="xl:container container-fluid m-auto px-4">
                        <div className='flex lg:flex-wrap justify-between items-center md:flex-col lg:flex-row'>
                            <div className="flex">
                                <a href={`${process.env.REACT_APP_BASE_URL}`}>
                                    {/* <img className="md:w-[140px] w-[120px]" src={header?.logo ? header?.logo : `${process.env.REACT_APP_FRONT_URL}assets/images/logo.svg`} alt="" /> */}
                                    <img className="md:w-[140px] w-[120px]" src={`${process.env.REACT_APP_FRONT_URL}assets/images/logo.svg`} alt="" />
                                </a>
                            </div>

                            <button id='navbar-dropdown-button' onClick={toggleNavbar} type="button" className="inline-flex items-center p-2 ml-3 text-sm bg-theme_blue text-theme_white rounded-lg md:hidden focus:outline-none focus:ring-0 dark:text-theme_white dark:hover:bg-theme_blue" aria-controls="navbar-dropdown" aria-expanded="false">
                                <span className="sr-only">Open main menu</span>
                                <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                            </button>
                            <div className="hidden md:block md:w-auto md:relative absolute right-0 left-0 md:right-0 md:left-0 top-[100%]" id="navbar-dropdown">
                                <ul onMouseLeave={() => { document.getElementById('dropdownNavbar').classList.add("hidden"); }} className="py-2.5 md:px-0 px-5 flex flex-col md:items-center bg-white md:flex-row md:gap-9 gap-5 md:mt-0 md:text-lg md:font-WorkSans_Medium md:border-0 dark:bg-white md:dark:bg-white">
                                    <li className='relative'>
                                        <button onMouseEnter={() => { document.getElementById('dropdownNavbar').classList.remove("hidden"); }} onClick={toggleNavDrop} id="dropdownNavbarLink" className="flex items-center justify-between w-full font-WorkSans_Medium text-lg">Products & services <svg className="w-5 h-5 ml-1 text-theme_red" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg></button>
                                        <div id="dropdownNavbar" className="z-10 absolute left-0 right-0 top-[100%] hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700 dark:divide-gray-600 float-right">
                                            <ul className="py-2 text-base text-theme_black" aria-labelledby="dropdownLargeButton">
                                                <li>
                                                    <a href={`${process.env.REACT_APP_BASE_URL}edukula`} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">EduKula</a>
                                                </li>
                                                <li>
                                                    <a href={`${process.env.REACT_APP_BASE_URL}smart-lxp`} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Smart LXP</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    {/* <li>
                                        <a href={`${process.env.REACT_APP_BASE_URL}`} className="block font-WorkSans_Medium text-lg text-theme_black hover:text-theme_orange " aria-current="page">Partner with us</a>
                                    </li> */}
                                    <li>
                                        <a href={`${process.env.REACT_APP_BASE_URL}free-trial`} className="block font-WorkSans_Medium text-lg md:bg-theme_orange md:text-white md:px-4 md:leading-[48px]" aria-current="page">Free trial</a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_BASE_URL}contact-us`} className="block font-WorkSans_Medium text-lg text-theme_black hover:text-theme_orange " aria-current="page">Contact</a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_BASE_URL}about-us`} className="block font-WorkSans_Medium text-lg text-theme_black hover:text-theme_orange " aria-current="page">About us</a>
                                    </li>
                                    {/* <li className='relative'>
                                        <button id="dropdownNavbarLink1" data-dropdown-toggle="dropdownNavbar1" className="block font-WorkSans_Regular text-theme_black hover:text-theme_red text-lg leading-[40px] transition duration-300 ease-in-out bg-[#f1f1f1] w-[40px] h-[40px] rounded-full text-center mt-[4px]"><i className="fa-regular fa-magnifying-glass"></i></button>
                                        <div id="dropdownNavbar1" className="z-10 absolute md:w-[300px] w-full left-0 right-0 top-[100%] hidden font-normal bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600">
                                            <form className="w-full float-left relative p-2">
                                                <input type="text" placeholder="Search..." className="float-left w-full p-2 border border-theme_blue font-WorkSans_Regular text-base text-black placeholder:text-gray-400 focus:outline-none pl-[10px] pr-[50px]" />
                                                <button type="submit" name="submit" value="Go" className="absolute right-2 top-2 h-[42px] w-[42px] border border-theme_blue bg-theme_orange text-white leading-8 float-right"><i className="fa-regular fa-magnifying-glass"></i></button>
                                            </form>
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="clear-both"></div>
            </header>
            <div className="clear-both"></div>
        </>
    )
};

export default CustNavbar;