import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Banner, Features, LearningExeperience, LearningPlatform, SecondBanner } from './components/SmartLXPSections';
import * as GeneralService from '../../services/general.service';

const SmartLXP = () => {
    useEffect(() => {
        //
    }, []);

    return (
        <>
            <Banner />
            <div className='clear-both'></div>

            <LearningPlatform /> 
            <div className='clear-both'></div>

            <LearningExeperience />
            <div className='clear-both'></div>

            <Features />
            <div className='clear-both'></div>

            <SecondBanner />
            <div className='clear-both'></div>

        </>
    )
};

export default SmartLXP;