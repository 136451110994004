import axios from "axios";
const END_POINT = `${process.env.REACT_APP_API_URL}`;

// All In One API

export const GetContentAPI = async (url) => {
    var return_data = null;
    var return_code = 200;
    var return_response = false;
    var return_error = null;

    const data = await axios.get(`${END_POINT}` + url)
        .then((response) => {
            return_response = true;
            return_data = response?.data?.data;
        }).catch((error) => {
            return_response = false;
            return_code = error?.code;
            return_error = error?.message;
        });

    return { data: return_data, code: return_code, response: return_response, errors: return_error };
}

// Form Post API

export const PostForm = async (url, form) => {
    var return_data = null;
    var return_code = 200;
    var return_response = false;
    var return_error = null;
    var return_status = null;
    var return_message = null;

    const data = await axios.post(`${END_POINT}` + url, form)
        .then((response) => {
            return_response = true;
            return_status = response.data?.status;
            return_data = response?.data?.data;
            return_message = response?.data?.message;
        }).catch((error) => {
            return_response = false;
            return_status = error?.status;
            return_code = error?.code;
            return_error = error?.message;
        });

    return { data: return_data, code: return_code, response: return_response, errors: return_error, status: return_status, message: return_message };
}