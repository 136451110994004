import React, { } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Banner, Detail } from './components/BlogDetailSections';

const BlogDetail = () => {
    const bag1 = `${process.env.REACT_APP_BASE_URL}assets/images/edukula_btm_banner.jpg`
    const {slug} = useParams();

    return (
        <>
            {/* <Banner />
            <div className='clear-both'></div> */}

            <Detail slug={slug} />
            <div className='clear-both'></div>

        </>
    )
};

export default BlogDetail;