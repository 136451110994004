import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as GeneralService from '../../../services/general.service';
import parse from 'html-react-parser';
import { HomeFeatureLoader, FeatureSectionLoader, BannerLoader } from 'components/interfaces/SkeletonLoader';

// checked
export const Banner = () => {
    const [processing, setProcessing] = useState(false);
    const [banner, setBanner] = useState('');
    useEffect(() => {
        fetchBanner();
    }, []);

    const fetchBanner = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-banners/edukula-banner');
        if (res.response) {
            setProcessing(false);
            setBanner(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <BannerLoader />
                    :
                    <>
                        {
                            banner !== '' && banner !== null
                                ?
                                <div className='float-left w-full min-h-[600px] lg:pt-0 pt-10 flex flex-wrap items-center relative overflow-hidden'>
                                    <div className='xl:container container m-auto px-4 relative z-[1]'>
                                        <div className='flex lg:flex-row flex-col items-center'>
                                            <div className='xl:w-7/12 lg:w-6/12 w-full md:text-left text-center'>
                                                {parse(banner?.long_description ? banner?.long_description : '')}
                                                <a href="https://www.edukula.com/" className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out" target="_blank">Get started</a>
                                            </div>
                                            <div className='xl:w-5/12 lg:w-6/12 w-full'>
                                                <img className="md:h-[600px] object-cover lg:float-right m-auto" src={banner?.image_url} alt={banner?.title} />
                                            </div>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const ForCorporates = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/for-corporates');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <HomeFeatureLoader image={'left'} showcta={false} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <div className='bg-theme_gray float-left w-full py-14 overflow-hidden relative'>
                                    <div className='container m-auto px-4'>
                                        <div className='float-left w-full'>
                                            <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-3'>{section?.title}</h2>
                                            {parse(section?.short_description ? section?.short_description : '')}
                                        </div>
                                        <div className='float-left w-full flex justify-between flex-wrap lg:flex-row flex-col gap-10 pt-4'>
                                            <div className='float-left lg:w-4/12 w-full relative'>
                                                <img className="md:h-[450px] object-cover lg:float-left m-auto lg:absolute lg:left-0 lg:right-0 lg:-bottom-[56px]" src={section?.image} alt={section?.title} />
                                            </div>
                                            <div className='float-left lg:max-w-[790px] w-full'>
                                                {parse(section?.long_description ? section?.long_description : '')}
                                            </div>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const ForLearners = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/for-learners');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <HomeFeatureLoader image={'right'} showcta={false} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <div className='bg-white float-left w-full py-14 overflow-hidden relative'>
                                    <div className='container m-auto px-4'>
                                        <div className='float-left w-full'>
                                            <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-3'>{section?.title}</h2>
                                            {parse(section?.short_description ? section?.short_description : '')}
                                        </div>
                                        <div className='float-left w-full flex justify-between flex-wrap lg:flex-row flex-col gap-10 pt-4'>
                                            <div className='float-left lg:w-4/12 w-full relative lg:order-2'>
                                                <img className="md:h-[420px] object-cover lg:float-left m-auto lg:absolute lg:left-0 lg:right-0 lg:-bottom-[56px]" src={section?.image} alt={section?.title} />
                                            </div>
                                            <div className='float-left lg:max-w-[790px] w-full'>
                                                {parse(section?.long_description ? section?.long_description : '')}
                                            </div>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const ForEducaters = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/for-educators');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <HomeFeatureLoader image={'left'} showcta={false} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <div className='bg-theme_gray float-left w-full py-14 overflow-hidden relative'>
                                    <div className='container m-auto px-4'>
                                        <div className='float-left w-full'>
                                            <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-3'>{section?.title}</h2>
                                            {parse(section?.short_description ? section?.short_description : '')}
                                        </div>
                                        <div className='float-left w-full flex justify-between flex-wrap lg:flex-row flex-col gap-10 pt-4'>
                                            <div className='float-left lg:w-4/12 w-full relative'>
                                                <img className="md:h-[430px] object-cover lg:float-left m-auto lg:absolute lg:left-0 lg:right-0 lg:-bottom-[56px]" src={section?.image} alt={section?.title} />
                                            </div>
                                            <div className='float-left lg:max-w-[790px] w-full'>
                                                {parse(section?.long_description ? section?.long_description : '')}
                                            </div>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const Features = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    const [features, setFeatures] = useState('');
    useEffect(() => {
        fetchSection();
        fetchFeatures();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('feature-section/edukula');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    const fetchFeatures = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-features?category_id=1&search=&is_pagination=1&page=1&length=8');
        if (res.response) {
            setProcessing(false);
            setFeatures(res?.data.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <FeatureSectionLoader count={8} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <div className='bg-white float-left w-full py-14 overflow-hidden relative'>
                                    <div className='container m-auto px-4'>
                                        <div className='float-left w-full pb-3'>
                                            <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-3'>Features</h2>
                                            <p className='text-lg text-theme_black pb-6 font-WorkSans_Regular'>{parse(section?.edukula?.long_description ? section?.edukula?.long_description : '')}</p>
                                        </div>
                                        <div className='float-left w-full grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 items-center justify-between pt-3 pb-10'>
                                            {
                                                typeof features !== 'undefined' && features !== '' && features !== null
                                                    ?
                                                    <>
                                                        {
                                                            features.map((feature, index) =>
                                                                <div key={index} className='float-left w-full shadow-md shadow-theme_black/6 rounded-lg p-3 font-Poppins_SemiBold text-lg text-theme_blue relative pl-[75px] min-h-[80px] items-center flex'>
                                                                    <img className="w-[50px] absolute left-[10px] top-[10px]" src={feature?.image_url} alt={feature?.title} />
                                                                    {feature?.title}
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className='float-left w-full text-center pt-3'>
                                            <a href={`${process.env.REACT_APP_BASE_URL}section/edukula_feature`} className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block m-auto py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out">Learn more</a>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }

        </>
    )
}

// checked
export const SecondBanner = () => {
    const [processing, setProcessing] = useState(false);
    const [banner, setBanner] = useState('');
    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-banners/visit-edukula-now');
        if (res.response) {
            setProcessing(false);
            setBanner(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                banner !== '' && banner !== null
                    ?
                    <div className='float-left w-full overflow-hidden relative bg-theme_gray'>
                        <div className='xl:container m-auto px-4 relative'>
                            <div className='float-left w-full md:h-[530px] h-[430px] md:px-10 px-5 bg-no-repeat bg-cover pt-6 flex flex-row gap-5 justify-between items-center' style={{ backgroundImage: `url(${banner?.image_url})` }}>
                                <div className='pb-6'>
                                    {parse(banner?.short_description ? banner?.short_description : '')}
                                    <a href="https://www.edukula.com" className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out" target="_blank">Get started</a>
                                </div>
                            </div>
                            <div className='clear-both'></div>
                        </div>
                    </div>
                    :
                    null
            }
        </>
    )
}