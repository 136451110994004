import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Banner, CTA, OurJourney, OurValues, Team } from './components/AboutSections';

const AboutUs = () => {
    const bag1 = `${process.env.REACT_APP_BASE_URL}assets/images/edukula_btm_banner.jpg`

    return (
        <>

            <Banner />
            <div className='clear-both'></div>

            <OurJourney />
            <div className='clear-both'></div>

            <OurValues />
            <div className='clear-both'></div>

            <CTA />
            <div className='clear-both'></div>

            <Team />
            <div className='clear-both'></div>

        </>
    )
};

export default AboutUs;