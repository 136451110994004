import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as GeneralService from '../../../services/general.service';
import parse from 'html-react-parser';
import { BannerLoader, BlockLoader } from 'components/interfaces/SkeletonLoader';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef } from 'react';
import Swal from 'sweetalert2';

// Currently not using
export const Banner = () => {
    const [processing, setProcessing] = useState(false);
    const [banner, setBanner] = useState('');
    useEffect(() => {
        fetchBanner();
    }, []);

    const fetchBanner = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-banners/contact-us');
        if (res.response) {
            setProcessing(false);
            setBanner(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <BannerLoader />
                    :
                    <>
                        {
                            banner !== '' && banner !== null
                                ?
                                <div className='float-left w-full min-h-[500px] lg:pt-0 pt-10 flex flex-wrap items-center relative overflow-hidden'>
                                    <div className='xl:container container m-auto px-4 relative z-[1]'>
                                        <div className='flex lg:flex-row flex-col items-center'>
                                            <div className='xl:w-7/12 lg:w-6/12 w-full md:text-left text-center'>
                                                {parse(banner?.long_description ? banner?.long_description : '')}
                                            </div>
                                            <div className='xl:w-5/12 lg:w-6/12 w-full'>
                                                <img className="md:h-[500px] object-cover lg:float-right m-auto" src={banner?.image_url} alt={banner?.title} />
                                            </div>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// Currently not using
export const ContactCMS = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/contact-us');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <BlockLoader width={1200} height={200} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <div className='bg-theme_gray float-left w-full py-14'>
                                    <div className='container m-auto px-4'>
                                        <div className='float-left w-full flex gap-16 lg:flex-row flex-col items-center justify-between'>
                                            {parse(section?.long_description ? section?.long_description : '')}
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const ContactForm = () => {
    const [processing, setProcessing] = useState(false);
    const [message, setMessage] = useState({});
    const [countries, setCountries] = useState({});
    const recaptchaRef = useRef();
    useEffect(() => {
        getCountries();
    }, []);

    const getCountries = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('country-list');
        if (res.response) {
            setProcessing(false);
            setCountries(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    const { register, handleSubmit, setError, clearErrors, formState: { errors }, reset } = useForm();

    const setScroll = () => {
        let scroll_box = document.getElementById('scroll_box');
        scroll_box.scrollIntoView({ behavior: 'smooth' });
    }

    const saveForm = async (form) => {
        let recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue === '') {
            setError('recaptcha', { type: 'custom', message: 'Verification is required!' });
            return false;
        }
        clearErrors('recaptcha');
        let formData = new FormData();
        formData.append('organization_name', form.organization_name);
        formData.append('country_id', form.country_id);
        formData.append('no_of_employee', form.no_of_employee);
        formData.append('first_name', form.first_name);
        formData.append('last_name', form.last_name);
        formData.append('email', form.email);
        formData.append('phone', form.phone);
        formData.append('recaptcha', recaptchaValue);

        setProcessing(true);
        const res = await GeneralService.PostForm('save-free-demo-details', formData);
        if (res.response) {
            reset();
            setProcessing(false);
            setScroll();
            recaptchaRef.current.reset();
            if (res?.status) {
                Swal.fire({
                    title: 'Thank You!',
                    html: 'We have received your information. You will receive the credentials for your free trial account by mail.',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Close'
                }).then((result) => {
                    if (result.isConfirmed) {
                        return null;
                    }
                });
            } else {
                setMessage({ type: 'error', message: res?.message });
            }
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <div className='bg-white float-left w-full py-14' id='scroll_box'>
            <div className='container m-auto px-4'>
                <h3 className='font-Poppins_SemiBold text-theme_red md:text-[32px] text-[20px] pb-3'>Start using Smart LXP</h3>
                <p className='font-Poppins_SemiBold text-lg text-theme_blue pb-1'>Fill in the information for your business, then click submit for a free trial.</p>
                {
                    typeof message !== 'undefined' && message !== ''
                        ?
                        <p className={`${message?.type === 'success' ? 'text-theme_orange' : 'text-theme_red'} font-WorkSans_Regular`}>{parse(message?.message ? message?.message : '')}</p>
                        :
                        null
                }
                <div className='float-left w-full pt-8 flex lg:gap-20 gap-0 lg:flex-row flex-col'>
                    <form onSubmit={handleSubmit(saveForm)} className='float-left w-full flex lg:gap-20 gap-0 lg:flex-row flex-col'>
                        <div className='float-left lg:w-6/12 w-full'>
                            <div className='float-left w-full mb-4'>
                                <input
                                    {...register("organization_name", { required: "Organization name is required!" })}
                                    placeholder="Organization name*"
                                    type="text"
                                    className="text-lg font-WorkSans_Regular block px-3 py-3 rounded-lg w-full bg-white border border-gray-300 text-black placeholder-gray-600 shadow-md focus:placeholder-white focus:bg-white focus:outline-none"
                                />
                                {errors.organization_name && <p className='text-theme_red font-WorkSans_Regular'>{errors.organization_name.message}</p>}
                            </div>
                            <div className='float-left w-full mb-4'>
                                <input
                                    {...register("first_name", { required: "First name is required!" })}
                                    placeholder="First name*"
                                    type="text"
                                    className="text-lg font-WorkSans_Regular block px-3 py-3 rounded-lg w-full bg-white border border-gray-300 text-black placeholder-gray-600 shadow-md focus:placeholder-white focus:bg-white focus:outline-none"
                                />
                                {errors.first_name && <p className='text-theme_red font-WorkSans_Regular'>{errors.first_name.message}</p>}
                            </div>
                            <div className='float-left w-full mb-4'>
                                <input
                                    {...register("last_name", { required: "Last name is required!" })}
                                    placeholder="Last name*"
                                    type="text"
                                    className="text-lg font-WorkSans_Regular block px-3 py-3 rounded-lg w-full bg-white border border-gray-300 text-black placeholder-gray-600 shadow-md focus:placeholder-white focus:bg-white focus:outline-none"
                                />
                                {errors.last_name && <p className='text-theme_red font-WorkSans_Regular'>{errors.last_name.message}</p>}
                            </div>
                            <div className='float-left w-full mb-4'>
                                <select
                                    {...register("country_id", { required: "Country is required!" })}
                                    className="text-lg font-WorkSans_Regular block px-3 py-3 rounded-lg w-full bg-white border border-gray-300 text-black placeholder-gray-600 shadow-md focus:placeholder-white focus:bg-white focus:outline-none"
                                >
                                    <option value="">Select country*</option>
                                    {
                                        Object.keys(countries).map((key) =>
                                            <option key={key} value={key}>{countries[key]}</option>
                                        )
                                    }
                                </select>
                                {errors.country_id && <p className='text-theme_red font-WorkSans_Regular'>{errors.country_id.message}</p>}
                            </div>
                            <div className='float-left w-full mb-4'>
                                <select
                                    {...register("no_of_employee", { required: "Number of employees is required!" })}
                                    className="text-lg font-WorkSans_Regular block px-3 py-3 rounded-lg w-full bg-white border border-gray-300 text-black placeholder-gray-600 shadow-md focus:placeholder-white focus:bg-white focus:outline-none"
                                >
                                    <option value="">Number of employees*</option>
                                    <option value="0-100">0-100</option>
                                    <option value="100-250">100-250</option>
                                    <option value="250-500">250-500</option>
                                    <option value="more than 500">more than 500</option>
                                </select>
                                {errors.no_of_employee && <p className='text-theme_red font-WorkSans_Regular'>{errors.no_of_employee.message}</p>}
                            </div>
                        </div>
                        <div className='float-left lg:w-6/12 w-full'>
                            <div className='float-left w-full mb-4'>
                                <input
                                    {...register("email", {
                                        required: "Email is required!",
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "invalid email address!"
                                        }
                                    })}
                                    placeholder="Work email*"
                                    type="text"
                                    className="text-lg font-WorkSans_Regular block px-3 py-3 rounded-lg w-full bg-white border border-gray-300 text-black placeholder-gray-600 shadow-md focus:placeholder-white focus:bg-white focus:outline-none"
                                />
                                {errors.email && <p className='text-theme_red font-WorkSans_Regular'>{errors.email.message}</p>}
                            </div>
                            <div className='float-left w-full mb-4'>
                                <input
                                    {...register("phone", {
                                        required: "Work phone is required!",
                                        pattern: {
                                            value: /^(\+\d{1,3}[- ]?)?\d{10,16}$/,
                                            message: "Please enter minimum 10 and maximum 16 digit"
                                        },
                                    })}
                                    placeholder="Work phone*"
                                    type="text"
                                    className="text-lg font-WorkSans_Regular block px-3 py-3 rounded-lg w-full bg-white border border-gray-300 text-black placeholder-gray-600 shadow-md focus:placeholder-white focus:bg-white focus:outline-none"
                                />
                                {errors.phone && <p className='text-theme_red font-WorkSans_Regular'>{errors.phone.message}</p>}
                            </div>
                            <div className='float-left w-full mb-4'>
                                <label className="font-WorkSans_Regular text-theme_black my-2.5 flex items-center">
                                    <input
                                        {...register("terms", { required: "Please accept!" })}
                                        className="leading-loose text-pink-600 top-0"
                                        type="checkbox"
                                    />
                                    <span className="ml-2 text-sm py-2 text-theme_black text-left">I agree to the <a href="#" className="font-WorkSans_Medium text-black  hover:text-theme_red"> Terms of use </a>and have read the <a href="#" className="font-WorkSans_Medium text-black  hover:text-theme_red"> Privacy policy</a>
                                    </span>
                                </label>
                                {errors.terms && <p className='text-theme_red font-WorkSans_Regular'>{errors.terms.message}</p>}
                            </div>
                            <div className='float-left w-full mb-4'>
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
                                    onChange={() => clearErrors('recaptcha')}
                                />
                                {errors.recaptcha && <p className='text-theme_red font-WorkSans_Regular'>{errors.recaptcha.message}</p>}
                            </div>
                            <div className='float-left w-full mb-4'>
                                <button
                                    type="submit"
                                    disabled={processing ? true : false}
                                    className="text-lg font-WorkSans_Regular block px-12 py-3 rounded-lg bg-theme_orange border border-gray-300 text-white cursor-pointer hover:bg-theme_blue"
                                >{processing ? <i className='fa fa-spinner fa-spin'></i> : 'Submit'}</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='clear-both'></div>
            </div>
        </div>
    )
}