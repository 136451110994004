import React from 'react';
import { AboutSection, Banner, Blogs, EdukulaFeature, Recognised, SecondBanner, SmartLXPFeature, WhatWeProvide } from './components/HomeSections';

const Home = () => {
    return (
        <>
            <Banner />
            <div className='clear-both'></div>

            <WhatWeProvide />
            <div className='clear-both'></div>

            <EdukulaFeature />
            <div className='clear-both'></div>

            <SmartLXPFeature />
            <div className='clear-both'></div>

            <AboutSection />
            <div className='clear-both'></div>

            <SecondBanner />
            <div className='clear-both'></div>

            <Blogs />
            <div className='clear-both'></div>

            <Recognised />
        </>
    )
};

export default Home;