import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as GeneralService from '../../../services/general.service';
import parse from 'html-react-parser';
import { BannerLoader, BlogLoader, ListLoader } from 'components/interfaces/SkeletonLoader';
import {LinkedinShareButton,TwitterShareButton,FacebookShareButton, LinkedinIcon,TwitterIcon,FacebookIcon} from "react-share";
// checked
export const Banner = () => {
    const [processing, setProcessing] = useState(false);
    const [banner, setBanner] = useState('');
    useEffect(() => {
        fetchBanner();
    }, []);

    const fetchBanner = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-banners/blogs-news');
        if (res.response) {
            setProcessing(false);
            setBanner(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <BannerLoader />
                    :
                    <>
                        {
                            banner !== '' && banner !== null
                                ?
                                <div className='float-left w-full min-h-[500px] lg:pt-0 pt-10 flex flex-wrap items-center relative overflow-hidden'>
                                    <div className='xl:container container m-auto px-4 relative z-[1]'>
                                        <div className='flex lg:flex-row flex-col items-center'>
                                            <div className='xl:w-7/12 lg:w-6/12 w-full md:text-left text-center'>
                                                {parse(banner?.long_description ? banner?.long_description : '')}
                                            </div>
                                            <div className='xl:w-5/12 lg:w-6/12 w-full'>
                                                <img className="md:h-[500px] object-cover lg:float-right m-auto" src={banner?.image_url} alt={banner?.title} />
                                            </div>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const Detail = ({ slug }) => {
    const [processing, setProcessing] = useState(false);
    const [blog, setBlog] = useState('');
    const current_url = window.location.href;
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (typeof slug === 'undefined' || slug === '' || slug === null) {
            navigate('/')
        }
        fetchBlog(slug);
    }, [location]);

    const fetchBlog = async (slug) => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI(`get-blog/${slug}`);
        if (res.response) {
            setProcessing(false);
            setBlog(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <BlogLoader />
                    :
                    <>
                        {
                            typeof blog !== 'undefined' && blog !== '' && blog !== null
                                ?
                                <div className='bg-theme_gray float-left w-full py-14'>
                                    <div className='container m-auto px-4'>
                                        <div className='float-left w-full flex gap-16 lg:flex-row flex-col items-start'>
                                            <div className='float-left lg:w-8/12 w-full blog_detail'>
                                                <h2>{blog?.title}</h2>
                                                <p className='date'>{blog?.formatted_date}</p>
                                                <img className="w-full object-cover float-left mb-4" src={blog?.image_url} alt={blog?.title} />
                                                <div className='clear-both'></div>
                                                {parse(blog?.long_description ? blog?.long_description : '')}
                                                <div>        
                                                <LinkedinShareButton title={blog?.title} summary={parse(blog?.long_description ? blog?.long_description : '')} source="{current_url}" url={current_url}><LinkedinIcon size={42} round={true} /></LinkedinShareButton>&nbsp;&nbsp;
                                                <TwitterShareButton title={blog?.title} url={current_url}><TwitterIcon size={42} round={true} /></TwitterShareButton>&nbsp;&nbsp;
                                                <FacebookShareButton title={blog?.title} summary={parse(blog?.long_description ? blog?.long_description : '')} source="{current_url}" url={current_url}><FacebookIcon size={42} round={true} /></FacebookShareButton>
                                               </div> 
                                            </div>
                                            <LatestPosts />
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
const LatestPosts = () => {
    const [processing, setProcessing] = useState(false);
    const [blogs, setBlogs] = useState('');
    useEffect(() => {
        fetchBlogs();
    }, []);

    const fetchBlogs = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-blogs?search&is_pagination=1&page=1&length=5');
        if (res.response) {
            setProcessing(false);
            setBlogs(res?.data.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <ListLoader count={5} />
                    :
                    <>
                        <div className='float-left lg:w-4/12 w-full sticky_position'>
                            <h3 className='font-Poppins_SemiBold text-theme_red md:text-[24px] text-[20px] pb-3 border-b-[4px] border-theme_blue'>Latest post</h3>
                            <ul className='float-left w-full'>
                                {
                                    typeof blogs !== 'undefined' && blogs !== '' && blogs !== null
                                        ?
                                        <>
                                            {
                                                blogs.map((blog, index) =>
                                                    <li key={index} className='text-lg py-2 pl-4 relative text-theme_black leading-6 font-WorkSans_Regular border-b last:border-0'>
                                                        <i className="text-[10px] absolute left-0 top-[16px] fa-solid fa-circle-small text-theme_red"></i>
                                                        <a href={`${process.env.REACT_APP_BASE_URL}blog-detail/${blog?.url}`} className='font-WorkSans_Regular text-lg text-theme_black hover:text-theme_orange'>{blog?.title}</a>
                                                    </li>
                                                )
                                            }
                                        </>
                                        :
                                        <>
                                            <p>No Blogs Found</p>
                                        </>
                                }
                            </ul>
                        </div>
                    </>
            }
        </>
    )
}