import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as GeneralService from '../../../services/general.service';
import parse from 'html-react-parser';
import { FeatureSectionLoader, BannerLoader, BlockLoader, CardsLoader } from 'components/interfaces/SkeletonLoader';


// checked
export const Banner = () => {
    const [processing, setProcessing] = useState(false);
    const [banner, setBanner] = useState('');
    useEffect(() => {
        fetchBanner();
    }, []);

    const fetchBanner = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-banners/about-us');
        if (res.response) {
            setProcessing(false);
            setBanner(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <BannerLoader />
                    :
                    <>
                        {
                            banner !== '' && banner !== null
                                ?
                                <div className='float-left w-full min-h-[500px] lg:pt-0 pt-10 flex flex-wrap items-center relative overflow-hidden'>
                                    <div className='xl:container container m-auto px-4 relative z-[1]'>
                                        <div className='flex lg:flex-row flex-col items-center'>
                                            <div className='xl:w-7/12 lg:w-6/12 w-full md:text-left text-center'>
                                                {parse(banner?.long_description ? banner?.long_description : '')}
                                            </div>
                                            <div className='xl:w-5/12 lg:w-6/12 w-full'>
                                                <img className="md:h-[500px] object-cover lg:float-right m-auto" src={banner?.image_url} alt={banner?.title} />
                                            </div>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const OurJourney = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/our-journey-to-transform-education-begins-here');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <BlockLoader width={1200} height={200} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <div className='bg-theme_gray float-left w-full py-14'>
                                    <div className='container m-auto px-4'>
                                        <div className='float-left w-full flex gap-16 lg:flex-row flex-col items-center justify-between'>
                                            <div className='float-left lg:w-4/12 w-full'>
                                                <h3 className='font-Poppins_SemiBold text-theme_red md:text-[32px] text-[20px] pb-3'>{section?.title}</h3>
                                            </div>
                                            <div className='float-left lg:w-8/12 w-full'>
                                                {parse(section?.long_description ? section?.long_description : '')}
                                            </div>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const OurValues = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/our-values');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <CardsLoader count={6} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <div className='bg-white float-left w-full py-14'>
                                    <div className='container m-auto px-4'>
                                        <div className='float-left w-full'>
                                            <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-4'>{section?.title}</h2>
                                            <div className='float-left w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 items-start justify-between pt-3'>
                                                {parse(section?.long_description ? section?.long_description : '')}
                                            </div>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}

// checked
export const CTA = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    useEffect(() => {
        fetchSection();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/your-organization-deserves-the-right-education-hr-technology-partner');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <BlockLoader width={1200} height={200} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <div className='bg-theme_gray float-left w-full py-14'>
                                    <div className='container m-auto px-4'>
                                        <div className='float-left w-full text-center'>
                                            {parse(section?.short_description ? section?.short_description : '')}
                                            <Link to={`${process.env.REACT_APP_BASE_URL}contact-us`} className="bg-theme_orange font-WorkSans_Medium text-base leading-10 inline-block py-1 px-8 text-white hover:bg-theme_blue transition duration-700 ease-in-out mt-3">Contact Us</Link>
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }

        </>
    )
}

// checked
export const Team = () => {
    const [processing, setProcessing] = useState(false);
    const [section, setSection] = useState('');
    const [team, setTeam] = useState('');
    useEffect(() => {
        fetchSection();
        fetchTeam();
    }, []);

    const fetchSection = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('get-cms/our-team');
        if (res.response) {
            setProcessing(false);
            setSection(res?.data);
        }
        else {
            setProcessing(false);
        }
    }

    const fetchTeam = async () => {
        setProcessing(true);
        const res = await GeneralService.GetContentAPI('members?search&is_pagination=1&page=1&length=10');
        if (res.response) {
            setProcessing(false);
            setTeam(res?.data?.data);
        }
        else {
            setProcessing(false);
        }
    }

    return (
        <>
            {
                processing
                    ?
                    <CardsLoader count={6} />
                    :
                    <>
                        {
                            section !== '' && section !== null
                                ?
                                <div className='bg-theme_white float-left w-full py-14'>
                                    <div className='container m-auto px-4'>
                                        <div className='float-left w-full'>
                                            <h2 className='text-theme_red md:text-[32px] text-[28px] font-Poppins_SemiBold pb-3'>{section?.title}</h2>
                                            {parse(section?.short_description ? section?.short_description : '')}
                                        </div>
                                        <div className='float-left w-full grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-14 items-start justify-between pt-3'>
                                            {
                                                typeof team !== 'undefined' && team !== '' && team !== null
                                                    ?
                                                    <>
                                                        {
                                                            team.map((member, index) =>
                                                                <div key={index} className='float-left w-full'>
                                                                    <img className="w-full object-cover pb-4 float-left" src={member?.image_url} alt={member?.name} />
                                                                    <div className='clear-both'></div>
                                                                    <h3 className='font-Poppins_SemiBold text-[24px] text-theme_red'>{member?.name}</h3>
                                                                    <p className='font-Poppins_SemiBold text-lg text-theme_blue pb-3'>{member?.designation}</p>
                                                                    <p className='font-WorkSans_Regular text-lg text-theme_black'>{member?.description}</p>
                                                                </div>
                                                            )
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className='clear-both'></div>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>
            }
        </>
    )
}