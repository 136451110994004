import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Banner, Features, ForCorporates, ForEducaters, ForLearners, SecondBanner } from './components/EdukulaSections';

const EduKula = () => {
    const bag1 = `${process.env.REACT_APP_BASE_URL}assets/images/edukula_btm_banner.jpg`

    return (
        <>
            <Banner />            
            <div className='clear-both'></div>

            <ForCorporates />  
            <div className='clear-both'></div>

            <ForLearners />  
            <div className='clear-both'></div>

            <ForEducaters />  
            <div className='clear-both'></div>

            <Features />  
            <div className='clear-both'></div>

            <SecondBanner />  
            <div className='clear-both'></div>
        </>
    )
};

export default EduKula;