import React, { } from 'react';
import { Link } from 'react-router-dom';
import { Banner, Blogs } from './components/BlogListSections';

const BlogListing = () => {
    const bag1 = `${process.env.REACT_APP_BASE_URL}assets/images/edukula_btm_banner.jpg`

    return (
        <>
            <Banner />
            <div className='clear-both'></div>

            <Blogs />
            <div className='clear-both'></div>

        </>
    )
};

export default BlogListing;